import { useEffect } from "react";
import Head from "next/head";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import * as GraphQl from "../utils/graphql";
import * as Analytics from "../utils/analytics";
import Notification, { NotificationProvider } from "../components/Noification";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    Analytics.init();
    const handleRouteChange = () => {
      Analytics.page();
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  let title = "MailMentor Platform";

  if (process.env.NEXT_PUBLIC_NODE_ENV != "production") {
    title = "(" + process.env.NEXT_PUBLIC_NODE_ENV + ") " + title;
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Head>
      <GraphQl.Provider>
        <NotificationProvider>
          <Component {...pageProps} />
          <Notification />
        </NotificationProvider>
      </GraphQl.Provider>
    </>
  );
}

export default MyApp;
