import React, { useReducer, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useLazyQuery,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import gql from "graphql-tag";
import * as processor from "../../processor";
import * as Analytics from "../../utils/analytics";
import { useNotification } from "../../components/Noification";
export { usePersonalizationMutation, PersonalizationFallback } from "./api";
import * as R from "ramda";

const httpLink = new BatchHttpLink({
  uri:
    process.env.NEXT_PUBLIC_GRAPHQL_URL ||
    "https://api-stag.svc.mailmentor.io/graphql",
  batchMax: 5,
  batchInterval: 20,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-access-token": accessToken,
      "x-refresh-token": refreshToken,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const nextClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export interface ContactUpdateInput {
  id?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  company?: string;
  title?: string;
  companyDescription?: string;
  companyCategory?: string;
  action?: "UPDATE" | "DELETE";
}

interface Props {
  children: any;
}
export interface Plan {
  id: number;
  title: string;
  description: string;
  features: string[];
  prices: {
    name: string;
    terms: string;
    amount: number;
    netAmount: number;
    id: string;
    period: string;
  }[];
  hasPromoApplied?: boolean;
  enabled?: boolean;
  hasPlan: boolean;
}

export interface User {
  id?: number;
  accountId: number;
  firstname: string;
  lastname: string;
  email: string;
  plan: processor.PlanTiers;
  hasPlan: boolean;
  shouldOnboard: boolean;
  sendableIdentities: string[];
  searchKey: string;
  account: {
    id: number;
    plans: any;
  };
}

interface Tokens {
  accessToken?: string;
  refreshToken?: string;
  userHash?: string;
  userListToken?: string;
}

interface Session {
  user?: User;
  tokens?: Tokens;
}

interface State {
  data: Session;
  loading?: boolean;
}

interface ActionPaylod {
  user?: User;
  tokens?: Tokens;
}

interface Action {
  type: string;
  payload: ActionPaylod;
}

interface ContextProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const initialState: State = {
  data: { user: undefined, tokens: undefined },
  loading: undefined,
};

export const SessionCtxt = React.createContext({
  state: initialState,
  dispatch: () => {},
} as ContextProps);

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case "LOADING":
      return {
        ...state,
        data: { ...state.data },
        loading: true,
      };
    case "READY":
      return {
        data: { ...state.data },
        loading: false,
      };
    default:
      throw new Error("Session reducer action not supported");
  }
};

export const Provider = function ({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getLoggedInUser, { loading, error, data }] = useLazyQuery(
    CURRENT_USER,
    {
      fetchPolicy: "network-only", // Used for first execution
      nextFetchPolicy: "network-only", // Used for subsequent executions
      client: client,
    },
  );

  useEffect(() => {
    if (loading && !state.loading) {
      dispatch({ type: "LOADING", payload: {} });
      return;
    }

    if (state.loading && (data || error)) {
      dispatch({ type: "READY", payload: {} });
      return;
    }
  }, [loading, state.loading, data, error]);

  // load user data from api
  useEffect(() => {
    if (!R.isNil(data?.loggedInUser)) {
      console.log("Api returned logged in user");
      const user = {
        ...data.loggedInUser,
        hasPlan: data.loggedInUser.account.plans.length > 0,
        //shouldOnboard: true,
      };
      dispatch({ type: "UPDATE", payload: { user } });
      const userCreated = Date.parse(user.createdat);
      Analytics.identify({
        email: user.email,
        id: user.id,
        user_id: user.id,
        name: user.firstname + " " + user.lastname,
        registered: true,
        hasPlan: user.hasPlan,
        signedUpAt: Math.floor(userCreated / 1000),
      });
    }

    if (!R.isNil(error)) {
      console.log("Api returned logged no logged in user");
      dispatch({
        type: "UPDATE",
        payload: { tokens: undefined },
      });
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }
  }, [data?.loggedInUser, state.loading, error]);

  // Load tokens into state and get user
  useEffect(() => {
    if (state.data.user || state.loading) {
      return;
    }

    if (state.data.tokens?.accessToken && state.data.tokens?.refreshToken) {
      localStorage.setItem("accessToken", state.data.tokens.accessToken);
      localStorage.setItem("refreshToken", state.data.tokens.refreshToken);
      console.log("get user");
      getLoggedInUser();
      return;
    }
  }, [state.data.user, state.data.tokens, getLoggedInUser, state.loading]);

  useEffect(() => {
    // Log user out
    if (R.isNil(state.data.user)) {
      return;
    }

    if (
      R.isNil(state.data.tokens?.accessToken) &&
      R.isNil(state.data.tokens?.refreshToken)
    ) {
      console.log("Logout");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      dispatch({ type: "UPDATE", payload: { user: undefined } });
      return;
    }
  }, [state.data.user, state.data.tokens]);

  // Load access token from storage on pageload
  useEffect(() => {
    if (state.data.user) {
      return;
    }

    console.log("loading tokens");
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken && refreshToken) {
      dispatch({
        type: "UPDATE",
        payload: { tokens: { accessToken, refreshToken } },
      });
    } else {
      dispatch({ type: "READY", payload: {} });
    }
  }, []);

  return (
    <SessionCtxt.Provider value={{ state, dispatch }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </SessionCtxt.Provider>
  );
};

interface UseSessionProps {
  redirectTo?: string;
}
export const useSession = function ({ redirectTo }: UseSessionProps = {}) {
  const { state, dispatch } = useContext(SessionCtxt);
  const router = useRouter();
  const [_, notify] = useNotification();

  const pushTo = (path: string, msg?: string) => {
    //console.log("pushTo", path, msg)
    router.push(path);
    notify({
      type: "NOTIFY",
      payload: {
        primary: msg || "You must be logged in to do that",
        timeoutSecs: 2.5,
      },
    });
  };

  useEffect(() => {
    if (state.loading == undefined || state.loading) {
      return;
    }

    if (state.data.tokens != undefined) {
      return;
    }

    if (state.data.user != undefined) {
      return;
    }

    if (redirectTo != undefined) {
      pushTo(redirectTo);
      return;
    }
  }, [state.loading, state.data.user, router, redirectTo]);

  const cleanupLocalStorage = () => {
    // HACK: cleans up local storage
    localStorage.removeItem("unsavedCampaign");
  };

  const signIn = (accessToken: string, refreshToken: string) => {
    dispatch({ type: "LOADING", payload: {} });
    dispatch({
      type: "UPDATE",
      payload: { tokens: { accessToken, refreshToken } },
    });
    dispatch({ type: "READY", payload: {} });
    cleanupLocalStorage();
  };

  const signOut = () => {
    dispatch({ type: "LOADING", payload: {} });
    dispatch({
      type: "UPDATE",
      payload: { tokens: undefined },
    });
    dispatch({ type: "READY", payload: {} });
    cleanupLocalStorage();
  };

  return { state, dispatch, signIn, signOut, pushTo, session: state };
};

export const LOGIN_QUERY = gql`
  mutation CreateLogin($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      account {
        id
      }
      email
      firstname
      lastname
      tokens {
        accessToken
        refreshToken
        userListToken
        intercomHash
      }
    }
  }
`;

export const ISSUE_MAGIC_LINK_MUTATION = gql`
  mutation IssueMagicLink($email: String!) {
    issueMagicSigninLink(email: $email)
  }
`;

export const LOGIN_MAGIC_TOKEN_MUTATOIN = gql`
  mutation LoginMagicToken($magicToken: String!) {
    loginMagicToken(magicToken: $magicToken) {
      id
      account {
        id
      }
      email
      firstname
      lastname
      tokens {
        accessToken
        refreshToken
        userListToken
        intercomHash
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $password: String!
    $passwordConfirm: String!
    $firstName: String!
    $lastName: String!
  ) {
    createUser(
      email: $email
      password: $password
      passwordConfirm: $passwordConfirm
      firstName: $firstName
      lastName: $lastName
    ) {
      id
      account {
        id
      }
      email
      firstname
      lastname
      tokens {
        accessToken
        refreshToken
        userListToken
        intercomHash
      }
    }
  }
`;

export const CURRENT_USER = gql`
  query {
    loggedInUser {
      id
      accountId
      firstname
      lastname
      email
      createdat
      shouldOnboard
      sendableIdentities
      searchKey
      account {
        id
        plans {
          expiresOn
          plan {
            id
            title
            description
          }
        }
      }
    }
  }
`;

export const COMPOSITIONS = gql`
  query {
    compositions {
      id
      body
      createdat
      review {
        body
        value
      }
    }
  }
`;

export const CREATE_COMPOSITION = gql`
  mutation CreateComposition(
    $body: String!
    $id: String
    $recipients: [RecipientInput]
  ) {
    createComposition(body: $body, id: $id, recipients: $recipients) {
      id
      body
    }
  }
`;

export const BILLING_MANAGEMENT_SESSION = gql`
  query BillingManagementSession {
    billingManagementSession {
      url
    }
  }
`;

export const FEDERATED_IDENTITIES_QUERY = gql`
  query FederatedIdentities {
    federatedIdentities {
      id
      firstName
      lastName
      provider
      profileUrl
      expiresAt
      email
    }
  }
`;

export const ACCOUNT_VIEW_QUERY = gql`
  query AccountViewQuery {
    billingManagementSession {
      url
    }
    currentUsage {
      dailyHistory {
        createdat
        units
        type
      }
    }
    federatedIdentities {
      id
      firstName
      lastName
      provider
      profileUrl
      expiresAt
      email
    }
    successStories {
      id
      companyName
      outcome
    }
  }
`;

export const CREATE_BILLING_CHECKOUT_SESSION = gql`
  mutation BillingCheckoutSession(
    $planId: Int!
    $callbackUrl: String!
    $discountCode: String
  ) {
    billingCheckoutSession(
      planId: $planId
      callbackUrl: $callbackUrl
      discountCode: $discountCode
    ) {
      url
    }
  }
`;

export const SCORE_COMPOSITION_MUTATION = gql`
  mutation ScoreComposition($body: String!) {
    scoreComposition(body: $body) {
      gradeLevel
      updatedAt
      sentanceCount
      wordCount
      characterCount
      recos {
        recommendation
        start
        end
        expected
        minPlanTier
        type
        units
        isLocked
      }
      readingTime {
        duration
        units
      }
    }
  }
`;

export interface Generation {
  documents: {
    id: string;
    body: string;
    tokensCount: number;
    characterCount: number;
  }[];
  units: number;
  currentUsage: number;
  usageSafetyLimit: number;
}

export const CREATE_COMPLETION_MUTATION = gql`
  mutation CreateCompletion($body: String!, $examples: Int) {
    completeComposition(body: $body, examples: $examples) {
      documents {
        id
        body
        tokensCount
        characterCount
      }
      units
      currentUsage
      usageSafetyLimit
    }
  }
`;

export interface Classification {
  label: string;
  minPlanTier: number;
  type: number;
}

export const GET_COMPOSITION_TONE_MUTATION = gql`
  mutation GetCompositionTone($body: String!) {
    getCompositionTone(body: $body) {
      minPlanTier
      label
      type
    }
  }
`;

export const GET_COMPOSITION_QUERY = gql`
  query GetComposition($id: String!) {
    composition(id: $id) {
      id
      userId
      body
      createdat
      recipients {
        email
        firstName
        lastName
      }
    }
  }
`;

export interface Composition {
  id: string;
  body: string;
  createdat: string;
  review?: {
    body: string;
    value: number;
  };
  recipients?: {
    email?: string;
    firstName?: string;
    lastName?: string;
  }[];
}

export interface MetaDataOption {
  label: string;
}

export interface MetaDataOptions {
  goals: MetaDataOption[];
  toneClasses: MetaDataOption[];
  audience: MetaDataOption[];
}

export const GET_METADATA_OPTIOPNS = gql`
  query MetaDataOptions {
    metaDataOptions {
      toneClasses {
        label
      }
      goals {
        label
      }
      audience {
        label
      }
    }
  }
`;

// Same query just fixed the typo until migrate in all components
export const GET_METADATA_OPTIONS = GET_METADATA_OPTIOPNS;

export const USE_COMPOSITION_WIZARD = gql`
  mutation CompositionWizard(
    $productDescription: String!
    $audienceDescription: String!
    $goals: [String]!
    $targetTone: String!
    $examples: Int
  ) {
    compositionWizard(
      productDescription: $productDescription
      audienceDescription: $audienceDescription
      goals: $goals
      targetTone: $targetTone
      examples: $examples
    ) {
      documents {
        id
        body
        tokensCount
        characterCount
      }
      units
      currentUsage
      usageSafetyLimit
    }
  }
`;

export const PERSONALIZED_COMPOSITION_MUTATION = gql`
  mutation PersonalizedComposition(
    $productDescription: String!
    $goals: [String]!
    $targetTone: String!
    $examples: Int
    $contact: ContactInput!
  ) {
    personalizedComposition(
      productDescription: $productDescription
      goals: $goals
      targetTone: $targetTone
      examples: $examples
      contact: $contact
    ) {
      documents {
        id
        body
        tokensCount
        characterCount
      }
      units
      currentUsage
      usageSafetyLimit
    }
  }
`;

export const CREATE_METADATA = gql`
  mutation CreateMetadata(
    $data: String!
    $metaDataType: MetaDataType!
    $id: String
  ) {
    createMetaData(data: $data, metaDataType: $metaDataType, id: $id) {
      id
    }
  }
`;

export const GET_FEDERATED_LOGIN_GOOGLE = gql`
  query GetFederatedLogin(
    $provider: IdentityProvider!
    $scopeLevel: ScopeLevel
  ) {
    getFederatedLogin(provider: $provider, scopeLevel: $scopeLevel) {
      provider
      url
    }
  }
`;

export const COMPLETE_FEDERATED_LOGIN_GOOGLE = gql`
  mutation CompleteFederatedLoginGoogle($token: String!) {
    completeFederatedLogin(provider: GOOGLE, token: $token) {
      id
      account {
        id
      }
      email
      firstname
      lastname
      tokens {
        accessToken
        refreshToken
        userListToken
        intercomHash
      }
      wasCreated
    }
  }
`;

// TODO: Add "DRAFT" action
export const SEND_USING_IDENTITY = gql`
  mutation SendUsingGmailIdentity(
    $compositionId: String!
    $action: ProviderAction!
    $provider: IdentityProvider!
  ) {
    sendUsingIdentity(
      compositionId: $compositionId
      action: $action
      provider: $provider
    ) {
      id
      status
    }
  }
`;

export const CREATE_REVIEW = gql`
  mutation CreateReview($body: String, $id: String!, $value: Int) {
    createReview(value: $value, body: $body, id: $id) {
      compositionId
      body
      value
    }
  }
`;

export interface SuccessStory {
  id: string;
  companyName: string;
  outcome: string;
}

export const CREATE_SUCCESS_STORY = gql`
  mutation CreateSuccessStory($companyName: String!, $outcome: String!) {
    createSuccessStory(companyName: $companyName, outcome: $outcome) {
      id
      companyName
      outcome
    }
  }
`;

export const DELETE_SUCCESS_STORY = gql`
  mutation DeleteSuccessStory($id: String) {
    deleteSuccessStory(id: $id)
  }
`;

export const SUCCESS_STORIES = gql`
  query SuccessStories {
    successStories {
      id
      companyName
      outcome
    }
  }
`;

export const PRICING_QUERY = gql`
  query Pricing($promoCode: String) {
    pricing(promoCode: $promoCode) {
      title
      description
      features
      hasPlan
      enabled
      prices {
        name
        terms
        amount
        netAmount
        id
        period
      }
    }
  }
`;

export const VALIDATE_CONTACT_MUTATION = gql`
  mutation ValidateContact($email: String!) {
    validateContact(email: $email) {
      email
      score
      validSyntax
      validMx
      suspectBounce
    }
  }
`;

export const CHANGE_PLAN_MUTATION = gql`
  mutation ChangePlan($planId: Int!, $isDowngrade: Boolean) {
    changePlan(planId: $planId, isDowngrade: $isDowngrade) {
      id
    }
  }
`;

export interface Contact {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  lastEnriched?: number;
  city?: string;
  state?: string;
  country?: string;
  companyUrl?: string;
  companyTags?: string[];
  companyTech?: string[];
  companyCategory?: string;
  companyDescription?: string;
  title?: string;
  seniority?: string;
  facebookHandle?: string;
  githubHandle?: string;
  twitterHandle?: string;
  linkedinHandle?: string;
  bio?: string;
  personalSite?: string;
  metaData: { [key: string]: string };
}

export const CONTACTS_QUERY = gql`
  query Contacts($limit: Int, $offset: Int) {
    contacts(limit: $limit, offset: $offset) {
      id
      email
      firstName
      lastName
      lastEnriched
      city
      state
      country
      company
      companyUrl
      companyTags
      companyTech
      companyCategory
      companyDescription
      title
      seniority
      facebookHandle
      githubHandle
      twitterHandle
      linkedinHandle
      bio
      personalSite
    }
  }
`;

export const CONTACT_QUERY = gql`
  query Contact($id: String) {
    contact(id: $id) {
      id
      email
      firstName
      lastName
      lastEnriched
      city
      state
      country
      company
      companyUrl
      companyTags
      companyTech
      companyCategory
      title
      seniority
      facebookHandle
      githubHandle
      twitterHandle
      linkedinHandle
      bio
      personalSite
    }
  }
`;

export const CREATE_CONTACTS_MUTATION = gql`
  mutation CreateContacts($contacts: [ContactUpdateInput]!) {
    createContacts(contacts: $contacts) {
      id
      email
      firstName
      lastName
      lastEnriched
      city
      state
      country
      company
      companyUrl
      companyTags
      companyTech
      companyCategory
      title
      seniority
      facebookHandle
      githubHandle
      twitterHandle
      linkedinHandle
      bio
      personalSite
    }
  }
`;

export const ENRICH_CONTACT_MUTATION = gql`
  mutation EnrichContact($id: String!) {
    enrichContact(id: $id) {
      id
      email
      firstName
      lastName
      lastEnriched
      city
      state
      country
      company
      companyUrl
      companyTags
      companyTech
      companyCategory
      title
      seniority
      facebookHandle
      githubHandle
      twitterHandle
      linkedinHandle
      bio
      personalSite
    }
  }
`;

export interface List {
  id: string;
  label: string;
  contactsCount: number;
  createdAt: string;
}

export const GET_LISTS = gql`
  query Lists($limit: Int, $offset: Int) {
    lists(limit: $limit, offset: $offset) {
      id
      label
      contactsCount
      createdAt
    }
  }
`;

export const UPDATE_LIST_MUTATION = gql`
  mutation UpdateList($id: String, $contactIds: [String], $label: String) {
    updateList(label: $label, contactIds: $contactIds, id: $id) {
      id
      contactsCount
    }
  }
`;

export const GET_LIST = gql`
  query List($id: String) {
    list(id: $id) {
      id
      label
      createdAt
      metaDataSchema
      contacts {
        id
        email
        firstName
        lastName
        lastEnriched
        city
        state
        country
        company
        companyUrl
        companyTags
        companyTech
        companyCategory
        title
        seniority
        facebookHandle
        githubHandle
        twitterHandle
        linkedinHandle
        bio
        personalSite
        metaData
      }
    }
  }
`;

export const GET_SEQUENCES = gql`
  query Sequences {
    sequences {
      id
      label
      status
      createdAt
      targetList {
        id
      }
      tasks {
        id
        subject
        dueAt
        type
        recipient {
          firstName
          lastName
          email
        }
        variants {
          id
          preview
          body
        }
      }
    }
  }
`;

export const GET_SEQUENCE = gql`
  query Sequence($id: String!) {
    sequence(id: $id) {
      id
      label
      status
      createdAt
      startAt
      targetList {
        id
      }
      touchpoints {
        id
        label
        units
        duration
        goal
        body
        sendAround
        subject
      }
      tasks {
        id
        subject
        dueAt
        type
        status
        recipient {
          firstName
          lastName
          email
        }
        variants {
          id
          preview
          body
        }
      }
    }
  }
`;

export const UPDATE_CONTACTS_ON_LIST_MUT = gql`
  mutation UpdateContactOnList(
    $listId: String!
    $contacts: [ContactUpdateInput]!
  ) {
    updateContactsOnList(listId: $listId, contacts: $contacts) {
      id
      email
      firstName
      lastName
      lastEnriched
      city
      state
      country
      company
      companyUrl
      companyTags
      companyTech
      companyCategory
      title
      seniority
      facebookHandle
      githubHandle
      twitterHandle
      linkedinHandle
      bio
      personalSite
    }
  }
`;

// Hack: Fallback for existing imports with wrong name
export const UPDATE_CONTACTS_ON_LIEST_MUT = UPDATE_CONTACTS_ON_LIST_MUT;

export interface IVariant {
  id: string;
  preview: string;
  body: string;
}

export interface ITask {
  id: string;
  type: "message";
  subject: string;
  dueAt: string;
  status: string;
  recipient: {
    firstName: string;
    lastName: string;
    email: string;
  };
  variants: IVariant[];
}

export interface ISequence {
  id: string;
  label: string;
  status: string;
  targetList?: { id: string };
  createdAt: string;
  tasks?: ITask[];
}

export interface IMessage {
  id: string;
  label: string;
  subject: string;
  units: number;
  duration: string;
  goal: string; // goal of message (should be "followup" in pretty much every case
  body: string; // body of message
  isComplete: boolean;
  sendAround: string;
}

export interface IFederatedIdentity {
  id: string;
  firstName: string;
  lastName: string;
  provider: string;
  profileUrl: string;
  expiresAt: string;
  email: string;
}

export const UPDATE_SEQUENCE_ITEM_MUT = gql`
  mutation UpdateSequenceItem($id: String!, $action: SequenceAction!) {
    updateSequenceItem(id: $id, action: $action) {
      id
      label
      createdAt
      targetList {
        id
      }
      tasks {
        id
        subject
        dueAt
        type
        recipient {
          firstName
          lastName
          email
        }
        variants {
          id
          preview
          body
        }
      }
    }
  }
`;

export interface CreateSequenceParams {
  messageType: string;
  goals: string[];
  targetRecipientTypes: string[];
  productDescription: string;
  messages: {
    units: number;
    duration: string;
    goal: string;
    body: string;
  }[];
}

export const CREATE_SEQUENCE_MUT = gql`
  mutation UpdateSequence($id: String, $sequenceInput: SequenceInput!) {
    updateSequence(id: $id, sequenceInput: $sequenceInput) {
      id
      label
      createdAt
      targetList {
        id
      }
      tasks {
        id
        subject
        dueAt
        type
        recipient {
          firstName
          lastName
          email
        }
        variants {
          id
          preview
          body
        }
      }
    }
  }
`;

export * as Mutations from "./mutations";
export * as Queries from "./queries";
