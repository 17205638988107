import { ReactNode, useEffect } from "react";
import { useSession } from "../utils/graphql";
import LoadingSpinner from "./Spinner";
import * as R from "ramda";
import { classNames } from "../utils";

export const RequiresLoginContainer = ({
  children,
}: {
  children?: ReactNode | ReactNode[];
}) => {
  const { session, pushTo } = useSession();

  useEffect(() => {
    if (session.loading == false && session.data.user === undefined) {
      pushTo("/sign-in");
    }
  }, [session.loading, session.data.user]);

  return (
    <Container loading={session.loading || R.isNil(session.data.user)}>
      {children}
    </Container>
  );
};

const Container = ({
  children,
  loading,
  className,
}: {
  children?: ReactNode | ReactNode[];
  loading?: boolean;
  className?: string;
}) => {
  const { session } = useSession();
  const myClass = loading ? "" : className;
  if (loading || session.loading) {
    return (
      <div className={classNames("rounded-lg p-10 bg-white shadow", myClass)}>
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
      </div>
    );
  }
  return (
    <div className={classNames("rounded-lg p-10 bg-white shadow", myClass)}>
      {children}
    </div>
  );
};

export default Container;
