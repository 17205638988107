import * as amplitude from "@amplitude/analytics-browser";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/nextjs";
import posthog from "posthog-js";

export const init = () => {
  if (process.env.NEXT_PUBLIC_NODE_ENV === "production") {
    amplitude.init("913e16458e7524b4183c881b3ef75fff");
    ReactGA.initialize("UA-57357039-2");
    TagManager.initialize({ gtmId: "GTM-TPFQB7" });
    posthog.init(
      process.env.NEXT_PUBLIC_POSTHOG_KEY ||
        "phc_27r4Kw5aKFtoD0dedSXZqmnBcbJ2pgzwFmpeNA0hW4K",
      {
        api_host:
          process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
        // Enable debug mode in development
        loaded: (ph: any) => {
          if (process.env.NODE_ENV === "development") ph.debug();
        },
      }
    );
  }
};

export const page = (path?: string) => {
  if (path === undefined) {
    path = window.location.pathname + window.location.search;
  }
  if (process.env.NEXT_PUBLIC_NODE_ENV === "production") {
    ReactGA.pageview(path);
    amplitude.track("PageView", { path: path });
    TagManager.dataLayer({ dataLayer: { event: "Pageview", pagePath: path } });
    posthog?.capture("$pageview");
  } else {
    console.log("PAGE", path);
  }
};

interface IdentifyProps {
  [index: string]: any;
}

export const identify = (props: IdentifyProps) => {
  const cleanProps: IdentifyProps = {};
  const id = props.user_id;

  Object.entries(props).forEach(([k, v]) => {
    if (
      k.toLowerCase().includes("token") ||
      ["user_id", "id"].includes(k.toLowerCase())
    ) {
      console.log("Ignoring", k);
      return;
    }

    cleanProps[k] = v;
  });

  if (process.env.NEXT_PUBLIC_NODE_ENV === "production") {
    const identObj = new amplitude.Identify();

    TagManager.dataLayer({ dataLayer: { event: "Identify", ...props, id } });

    if (id) {
      Sentry.setUser({ ...cleanProps, id });
      amplitude.setUserId(id);
    }

    Object.entries(cleanProps).forEach(([k, v]) => {
      identObj.set(k, v);
    });

    amplitude.identify(identObj);
    posthog?.identify(id, props);
  } else {
    console.log("IDENTIFY", cleanProps);
  }
};

interface TrackProps {
  [index: string]: string | number | boolean;
}

export const track = (event: string, props?: TrackProps) => {
  if (process.env.NEXT_PUBLIC_NODE_ENV === "production") {
    amplitude.track(event, props);
    ReactGA.event({
      category: "General",
      action: event,
    });
    TagManager.dataLayer({ dataLayer: { event, ...props } });
    posthog?.capture(event, props);
  } else {
    console.log("TRACK", event, props);
  }
};
