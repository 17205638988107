import gql from "graphql-tag";

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument(
    $key: String!
    $bucket: String!
    $uploadType: UploadType!
    $status: DocumentStatus
  ) {
    updateDocument(
      key: $key
      bucket: $bucket
      uploadType: $uploadType
      status: $status
    ) {
      key
      bucket
      status
      summary
    }
  }
`;

export const CREATE_FILE_UPLOAD_AUTH = gql`
  mutation CreateFileUploadAuth(
    $fileType: String!
    $fileName: String!
    $uploadType: UploadType!
  ) {
    createFileUploadAuth(
      fileType: $fileType
      fileName: $fileName
      uploadType: $uploadType
    ) {
      url
      bucket
      key
      policy
      xAmzAlgorithm
      xAmzCredential
      xAmzDate
      xAmzSignature
      xAmzSecurityToken
    }
  }
`;

export interface UpdateChatRes {
  chatId: string;
  body: string;
  role: string;
  tokensCount: number;
  characterCoun: number;
}

export const UPDATE_CHAT_MUTATION = gql`
  mutation UpdateChat($id: String, $message: String!) {
    updateChat(id: $id, message: $message) {
      id
      chatId
      body
      role
      tokensCount
      characterCount
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign(
    $sendAs: String!
    $startAt: Date
    $label: String!
    $listId: String!
    $messages: [SequenceMessageInput]!
  ) {
    createCampaign(
      sendAs: $sendAs
      startAt: $startAt
      label: $label
      listId: $listId
      messages: $messages
    ) {
      id
      label
      createdAt
      startAt
      targetList {
        id
      }
      touchpoints {
        label
        units
        duration
        goal
        body
        sendAround
        subject
      }
      tasks {
        id
        subject
        dueAt
        type
        recipient {
          firstName
          lastName
          email
        }
        variants {
          id
          preview
          body
        }
      }
    }
  }
`;

export const ACT_ON_TASKS = gql`
  mutation ActOnTasks($id: String!, $action: TaskAction!) {
    actOnTask(id: $id, action: $action) {
      id
      subject
      dueAt
      type
      recipient {
        firstName
        lastName
        email
      }
      variants {
        id
        preview
        body
      }
    }
  }
`;

export const UPDATE_SEQUENCE = gql`
  mutation UpdateSequence($id: String, $sequenceInput: SequenceInput!) {
    updateSequence(id: $id, sequenceInput: $sequenceInput) {
      id
      label
      status
      createdAt
      targetList {
        id
      }
      tasks {
        id
        subject
        dueAt
        type
        recipient {
          firstName
          lastName
          email
        }
        variants {
          id
          preview
          body
        }
      }
    }
  }
`;

export const UPDATE_WEBHOOK = gql`
  mutation UpdateWebhook(
    $action: CrudAction!
    $event: String!
    $url: String!
    $id: String
  ) {
    updateWebhook(action: $action, event: $event, url: $url, id: $id) {
      id
      url
    }
  }
`;

export const RUN_AGENT = gql`
  mutation RunAgent($urlTarget: String, $doc: String, $agentType: AgentType) {
    runAgent(urlTarget: $urlTarget, doc: $doc, agentType: $agentType) {
      id
    }
  }
`;

export const CREATE_CALENDAR_NOTE_MUT = gql`
  mutation CreateCalendarNote($id: String!, $file: String!) {
    createCalendarNote(id: $id, file: $file)
  }
`;
