import {
  Reducer,
  Dispatch,
  Fragment,
  useState,
  useEffect,
  createContext,
  useReducer,
  useContext,
} from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon as XIcon } from "@heroicons/react/24/solid";

const initialState = {
  data: {
    primary: "",
    timeoutSecs: 0,
  },
  display: false,
};

interface Data {
  primary: string;
  cta?: {
    primary: string;
    onClick: () => void;
  };
  timeoutSecs: number;
}

interface State {
  data: Data;
  display: boolean;
}

interface Action {
  type: string;
  payload?: Data;
}

type NotificationContext = [State, Dispatch<Action>];

export const Context = createContext([
  initialState,
  () => {},
] as NotificationContext);

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "NOTIFY":
      if (!action.payload) {
        return state;
      }

      return {
        data: {
          ...state.data,
          ...action.payload,
        },
        display: true,
      };
    case "CLEAR":
      return {
        data: {
          primary: "",
          timeoutSecs: 0,
        },
        display: false,
      };
    default:
      throw new Error();
  }
};

export function NotificationProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>
      {props.children}
    </Context.Provider>
  );
}

export const useNotification = () => {
  return useContext(Context);
};

export default function Notification() {
  const [state, dispatch] = useNotification();
  const [timeoutId, setTimeoutId] =
    useState<ReturnType<typeof setTimeout> | undefined>();

  const show = state.display;

  useEffect(() => {
    if (state.display) {
      clearTimeout(timeoutId);
      const id = setTimeout(() => {
        dispatch({ type: "CLEAR" });
      }, state.data.timeoutSecs * 1000);
      setTimeoutId(id);
    }
  }, [state.display, setTimeoutId]);

  // TODO: set notification to pull from LS if there is one, you will need to change the "timeoutSecs" to have the expire time
  //useEffect(() => {
  //const rawNotification = window.localStorage.getItem("notification");
  //if (rawNotification) {
  //window.localStorage.removeItem("notification");
  //const notification = JSON.parse(rawNotification) as Data;
  //dispatch({
  //type: "NOTIFY",
  //payload: {
  //primary: notification.primary,
  //timeoutSecs: notification.timeoutSecs,
  //},
  //});
  //}
  //}, []);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-20"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-center">
                  <div className="w-0 flex-1 flex justify-between">
                    <p className="w-0 flex-1 text-sm font-medium text-gray-900">
                      {state.data.primary}
                    </p>
                    {state.data.cta ? (
                      <button
                        type="button"
                        className="ml-3 flex-shrink-0 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={state.data.cta.onClick}
                      >
                        {state.data.cta.primary}
                      </button>
                    ) : null}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        dispatch({ type: "CLEAR" });
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
