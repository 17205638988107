import md5 from "md5";

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const setItem = (key: string, value: string): boolean => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, value);
  }
  return true;
};

export const getItem = (key: string): string | null => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(key);
  }
  return null;
};

export const deleteItem = (key: string): void => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(key);
  }
};

export const getGravitar = (email?: string) => {
  const emailSum = md5(email || "");
  return `https://www.gravatar.com/avatar/${emailSum}?d=identicon`;
};

export const humanReadableSize = (fileSize: number): string => {
  if (fileSize < 1000000) {
    return Math.floor(fileSize / 1000) + " KB";
  } else {
    return Math.floor(fileSize / 1000000) + " MB";
  }
};
