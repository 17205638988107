import gql from "graphql-tag";

export interface IStats {
  companies: string;
  collectedContacts: string;
}

export const GET_STATS_QUERY = gql`
  query Stats {
    stats {
      companies
      collectedContacts
    }
  }
`;

export interface CaseStudyV2 {
  id: string;
  label?: string;
  description?: string;
  fileName?: string;
  size: number;
  status: string;
  summary: string;
  createdAt: string;
}

export const GET_CASE_STUDIES = gql`
  query CaseStudies {
    caseStudies {
      id
      label
      description
      fileName
      size
      status
      summary
      createdAt
    }
  }
`;

export const CHAT_MESSAGE = gql`
  query ChatMessage($id: String!, $chatId: String!) {
    chatMessage(id: $id, chatId: $chatId) {
      id
      chatId
      body
      role
      tokensCount
      characterCount
      status
    }
  }
`;

export const TASKS_QUERY = gql`
  query Tasks($taskStatus: TaskStatus, $campaignId: String!) {
    tasks(taskStatus: $taskStatus, campaignId: $campaignId) {
      id
      subject
      dueAt
      type
      status
      completedAt
      recipient {
        firstName
        lastName
        email
      }
      variants {
        id
        preview
        body
      }
    }
  }
`;

export interface ISocialProfile {
  service: string;
  profileType: string;
  url: string;
}

export interface ICompany {
  id: string;
  label: string;
  domain?: string;
  emailDomain?: string;
  mxValid?: boolean;
  socialProfiles?: ISocialProfile[];
  rawPhysicalAddress?: string;
}

export interface IEntity {
  id: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string; // valid email address
  contactTitle?: string;
  phoneNumber?: string; // valid phone number
  company?: ICompany;
  source: string;
  jobId: string;
  createdAt: Date;
  emailConfidence: string;
  alternateAddresses?: { email: string; confidence: string }[];
  currentPosition: boolean;
}

export const ENTITIES_QUERY = gql`
  query Entities($limit: Int, $offset: Int) {
    entities(limit: $limit, offset: $offset) {
      id
      jobId
      status
      firstName
      lastName
      source
      company {
        id
        label
        domain
        emailDomain
        mxValid
        rawPhysicalAddress
        socialProfiles {
          service
          profileType
          url
        }
      }
      phoneNumber
      contactTitle
      createdAt
      emailAddress
      alternateAddresses {
        email
        confidence
      }
      currentPosition
    }
  }
`;

export const ACT_ON_ENTITIES_MUT = gql`
  mutation ActOnEntity(
    $id: String!
    $action: EntityAction!
    $exceptionNote: String
    $listId: String
  ) {
    actOnEntity(
      id: $id
      action: $action
      exceptionNote: $exceptionNote
      listId: $listId
    )
  }
`;

export const GET_NER_COMPANIES = gql`
  query Companies($limit: Int, $offset: Int) {
    companies(limit: $limit, offset: $offset) {
      id
      label
      domain
      emailDomain
      mxValid
      rawPhysicalAddress
      socialProfiles {
        service
        profileType
        url
      }
    }
  }
`;

export const GET_WEBHOOKS = gql`
  query Webhooks($limit: Int, $offset: Int) {
    webhooks(limit: $limit, offset: $offset) {
      id
      url
      event
    }
  }
`;

export interface IScan {
  id: string;
  query: string;
  status: string;
}

export const SCANS_QUERY = gql`
  query Scans($limit: Int, $offset: Int) {
    scans(limit: $limit, offset: $offset) {
      id
      query
      status
    }
  }
`;

export interface IInvoice {
  id: string;
  url: string;
}

export const INVOICE_HISTORY_QUERY = gql`
  query InvoiceHistory {
    invoiceHistory {
      id
      url
      createdAt
    }
  }
`;

export interface INote {
  id: string;
  associatedId: string;
  src: string;
  label?: string;
  summary?: string;
  createdAt: string;
}

export const NOTES_QUERY = gql`
  query Notes($where: NotesWhereInput) {
    notes(where: $where) {
      id
      associatedId
      src
      label
      summary
      createdAt
    }
  }
`;

export interface ICalendarEvent {
  id: string;
  label: string;
  location: string;
  startAt: Date;
  endAt: Date;
}

export const GET_CALENDAR_EVENTS = gql`
  query Calendar($startAt: Date, $endAt: Date) {
    calendar(startAt: $startAt, endAt: $endAt) {
      id
      label
      startAt
      endAt
    }
  }
`;
