import { useEffect } from "react";
import { useRouter } from "next/router";
import { useMutation, DocumentNode } from "@apollo/client";
import { PERSONALIZED_COMPOSITION_MUTATION } from "../../utils/graphql";
import { useNotification } from "../../components/Noification";
import ErrorPage from "../../components/ErrorPage";
import * as Analytics from "../../utils/analytics";
import * as R from "ramda";
import { useErrorBoundary } from "react-error-boundary";

interface PersonalizationProps {
  productDescription: string;
  targetTone: string;
  goals: string[];
  examples: string;
  personalNote: string;
  contact: {
    firstName: string;
    lastName: string;
    first: string;
    last: string;
    email: string;
  };
}

export function PersonalizationFallback({ error, resetErrorBoundary }: any) {
  const router = useRouter();
  const [_, notify] = useNotification();
  console.log("err triggered", error);

  useEffect(() => {
    if (R.isNil(error)) {
      return;
    }
    switch (error.message) {
      case `Unexpected error value: "Must authenticate"`:
        notify({
          type: "NOTIFY",
          payload: {
            primary: `Sign up to use the AI generator`,
            timeoutSecs: 5,
          },
        });
        router.push("/sign-up");
        Analytics.track("WizardGenerateMustAuthenicate");
        break;
      case `Unexpected error value: "Account has reached its max completions. This is a safety feature please contact support to remove the safety."`:
        notify({
          type: "NOTIFY",
          payload: {
            primary:
              "Account has reached its max generations. Please contact support to increase limit.",
            timeoutSecs: 5,
          },
        });
        Analytics.track("WizardGenerateMaxCompletions");
        break;
      case `Unexpected error value: "Account has reached its max usage for feature. Please upgrade."`:
        notify({
          type: "NOTIFY",
          payload: {
            primary:
              "You gave reached your maximum usage for this feature and need upgrade your plan to continue.",
            timeoutSecs: 30,
          },
        });
        Analytics.track("WizardGenerateNeedsPlan");
        router.push("/select-plan");
        break;
      case `Unexpected error value: "This feature is not included the plan, please upgrade."`:
        notify({
          type: "NOTIFY",
          payload: {
            primary:
              "This feature is not included in your plan, please upgrade.",
            timeoutSecs: 30,
          },
        });
        Analytics.track("WizardGenerateNeedsPlan");
        router.push("/select-plan");
        break;

      default:
        notify({
          type: "NOTIFY",
          payload: {
            primary:
              "There was an error generating the personalization. Please try again",
            timeoutSecs: 15,
          },
        });
    }
    resetErrorBoundary();
  }, [error, router, resetErrorBoundary]);
  return (
    <ErrorPage primary="Something went wrong" secondary="Please try again" />
  );
}

export const usePersonalizationMutation = (): any[] => {
  const [_, notify] = useNotification();
  const router = useRouter();
  const { showBoundary } = useErrorBoundary();

  const [personalizationMutation, args] = useMutation(
    PERSONALIZED_COMPOSITION_MUTATION
  );

  const makeCall = async (props: {
    variables: PersonalizationProps;
  }): Promise<any> => {
    console.log("making call with", props);
    try {
      return await personalizationMutation(props);
    } catch (err) {
      showBoundary(err);
    }
  };

  return [makeCall, args];
};
